import React from "react"
import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"
import underlineImg from "../images/landing/underline-scribble.svg"
import phoneIcon from "../images/application/phone-icon.svg"
import houseIcon from "../images/landing/address-icon.svg"
import mailIcon from "../images/landing/mail-icon.svg"
import ContactForm from "../components/home/Contact/ContactForm"

const Contact = () => {
  return (
    <PageWrapper>
      <Header>
        <h1>Contact us</h1>
        <h2>
          Get in touch with us
          <span />
        </h2>
        <p>
          We take our commitment to our users seriously. If you need help with
          your user account, have questions <br />
          about how to use the platform or are experiencing technical
          difficulties, please do not hesitate the contact us.
        </p>
      </Header>
      <Container>
        <div className="address">
          <div>
            <img src={houseIcon} />
            <p>
              F25, Parkview Shopping Centre <br />
              Garsfontein Road &, Netcare St, <br />
              Moreletapark, Pretoria, 0145
            </p>
          </div>
          <div>
            <img src={phoneIcon} />
            <a href="tel:0873650799">087 365 0799</a>
          </div>
          <div>
            <img src={mailIcon} />
            <a href="mailto:clientcare@qphoto.co.za">clientcare@qphoto.co.za</a>
          </div>
        </div>
        <ContactForm />
      </Container>
    </PageWrapper>
  )
}

const Header = styled.div`
  margin: 50px 0;
  text-align: center;
  position: relative;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
  }
  h2 {
    font-weight: normal;
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  p {
    font-size: 0.8rem;
    padding-top: 50px;
    color: ${props => props.theme.color.grey};
  }
  span {
    background-image: url(${underlineImg});
    background-repeat: no-repeat;
    background-position: 50%;
    height: 10px;
    display: block;
  }
  .subheading {
    font-size: 0.8rem;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 1.6rem;
      position: absolute;
      left: 50%;
      transform: rotate(-2deg);
    }
    p {
      font-size: 1rem;
      padding-top: 75px;
    }
    span {
      transform: translateX(15px);
      background-position: right;
      height: 6px;
    }
  }
`

const Container = styled.div`
  display: grid;
  .address {
    div {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      font-family: ${props => props.theme.font.galanoMed};
      img {
        width: 15px;
        margin: 5px 15px 0 0;
      }
      a {
        transition: 0.25s ease;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
  @media (min-width: 1024px) {
    padding: 0 15%;
  }
`

export default Contact
