import React, { useState } from "react"
import { Formik, Field } from "formik"
import * as yup from "yup"
import { EmailField, SubmitButton, TextField } from "../../elements"
import { notify } from "../../../state"
import { useDispatch } from "react-redux"

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Required"),
  message: yup.string().required("Required"),
})

const ContactForm = () => {
  const [formSubmit, setFormSubmit] = useState(false)
  const dispatch = useDispatch()
  const submitForm = (values, formActions) => {
    formActions.setSubmitting(true)
    try {
      dispatch(
        notify({
          message: `Message Sent`,
          variant: "info",
        })
      )
      setFormSubmit(true)
    } catch (err) {
      dispatch(
        notify({
          message: `Something went wrong: ${err}`,
          variant: "error",
        })
      )
    } finally {
      formActions.setSubmitting(false)
    }
  }
  return (
    <div>
      <Formik
        initialValues={{ firstName: "", email: "", message: "" }}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              autoComplete="given-name"
              component={TextField}
              id="firstName"
              label="Name"
              name="firstName"
              required
            />
            <Field
              component={EmailField}
              id="email"
              label="Email Address"
              name="email"
              required
            />
            <Field
              component={TextField}
              id="message"
              label="Message"
              name="message"
              required
              multiline
              rows="8"
            />
            <SubmitButton
              variant="contained"
              fullWidth
              loading={isSubmitting}
              disabled={isSubmitting || formSubmit}
              className="submit contact"
            >
              SEND
            </SubmitButton>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
